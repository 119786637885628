
@charset "UTF-8";
@font-face {
  font-family: TmonMonsori;
  src: url('Tmon-Monsori-Regular.eot');
  src: url('Tmon-Monsori-Regular.woff'),
    url('Tmon-Monsori-Regular.ttf');
}
/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
  padding: 0;
  color: #1d1d1d;
}
/* 리셋 */
html,
body {
  height: 100%;
}
body {
  font-family: "Noto Sans KR", "Roboto", sans-serif;
  font-size: 20px;
}
/* You can add global styles to this file, and also import other style files */
/* reset */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
em,
img,
ins,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 20px;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "Noto Sans KR", "Roboto", sans-serif;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ul,
li,
dl,
dd,
dt {
  list-style: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: #000;
}
input,
select {
  vertical-align: middle;
  box-sizing: border-box;
}
small {
  font-size: 80%;
}
img {
  border: 0;
  border-style: none;
}
address {
  font-style: normal;
}
button,
input,
textarea,
select,
a {
  border: 0;
  outline: none;
  resize: none;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.cf:after {
  content: "";
  clear: both;
  display: block;
}
.mb {
  margin-bottom: 20px;
}
/** Correct the inability to style clickable types in iOS and Safari. */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/** * Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/** * Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/** * 1. Add the correct box sizing in IE 10. * 2. Remove the padding in IE 10. */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
/** * Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/** * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari. */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/** * Remove the inner padding in Chrome and Safari on macOS. */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/** * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to inherit in Safari. */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
